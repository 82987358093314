<template>
  <div>
    <div class="d-flex justify-space-between px-3 pt-3 pb-3">
      <div>
        <v-text-field v-if="showSearchBox" v-model="searchTextLocal" append-icon="mdi-magnify" label="Search" single-line hide-details dense></v-text-field>
      </div>
      <slot />
      <div class="d-flex">
        <v-select
          class="table-search"
          background-color="#f5f5f5"
          solo
          v-model="selectedHeadersLocal"
          :items="headersLocal.filter((i) => i.hideable)"
          item-text="text"
          label="Select Columns"
          :menu-props="{ 'offset-y': true }"
          multiple
          dense
          single-line
          hide-details
          height="20"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="text-gray-700 py-1 text-14">
              <v-icon small>mdi-eye</v-icon>
              {{ selectedHeadersLocal.length }} Selected Column(s)
            </span>
          </template>
        </v-select>

        <v-menu v-if="showExport && tableId" transition="slide-y-transition" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-3" v-bind="attrs" v-on="on" elevation="1">
              Export
              <v-icon small class="ml-2">mdi-download</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in exportItems" :key="i" class="cursor-pointer" link dense @click="exportClicked(item.extension)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
/* global XLSX */
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';

export default {
  name: 'DataTableHeader',
  props: {
    headers: Array,
    selectedHeaders: Array,
    data: Array,
    showExport: Boolean,
    showSearchBox: Boolean,
    tableId: String,
  },
  data() {
    return {
      selectedHeadersLocal: '',
      headersLocal: '',
      searchTextLocal: '',
      exportItems: [
        { title: 'Export as PDF', extension: 'pdf' },
        { title: 'Export as XLS', extension: 'xls' },
      ],
    };
  },
  created() {
    this.headersLocal = this.headers;
    this.selectedHeadersLocal = this.headers.filter((h) => h.hideable && h.show).map((h) => h.value);
    this.searchTextLocal = this.searchText;
  },
  methods: {
    exportClicked(extension) {
      const table = document.querySelector(`#${this.tableId}`);
      table.classList.add('hide-actions');

      if (extension === 'pdf') {
        this.exportAsPdf();
      } else if (extension === 'xls') {
        this.exportAsXls();
      }

      this.$nextTick(() => {
        table.classList.remove('hide-actions');
      });
    },
    exportAsPdf() {
      const doc = new jsPDF();
      doc.autoTable({ html: `#${this.tableId}` });
      doc.save('table.pdf');
    },
    exportAsXls() {
      const table = document.querySelector(`#${this.tableId}`);
      const wb = XLSX.utils.table_to_book(table, { sheet: 'Sheet 1' });

      return XLSX.writeFile(wb, 'table.' + 'xlsx');
    },
  },
  watch: {
    searchTextLocal() {
      this.$emit('update:search', this.searchTextLocal);
    },
    selectedHeadersLocal() {
      this.headers.forEach((h) => {
        h.show = this.selectedHeadersLocal.includes(h.value);
      });
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>

<style lang="scss">
table.hide-actions {
  tr th:last-child {
    display: none;
  }
  tr td:last-child {
    display: none;
  }
}

.table-search {
  //background-color: #f5f5f5;
}

.table-search .v-input__slot {
  min-height: 36px !important;
  //box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
</style>
